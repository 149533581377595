.content {
  max-width: 1316px;
  margin: 0 auto;
}

.lineWrapper  {
  background: #fff;
}

.headerNav {
  margin: 0 auto 2em;
  max-width: 1040px !important;
  justify-content: space-between;
}

.destination > div.image > div.flightAndHotelIcon {
  margin-top: 200px;
  margin-right: 175px;
}

.baggage_options {
  margin: 0 .25em;
}

.destination .flightAndHotelIcon {
  position: absolute;
}

div#afnu_filters_v2_wrapper {
  padding: 0;
}

div#afnu_filters_v2_wrapper div.window {
  padding: 0;
}

.symbols {
  max-width: 230px;
  flex-wrap: wrap;
}

/*filters related */
.destination .title {
  display: none;
}

.destination .icons_wrapper {
  font-size: 1.75em !important;
}

.destination > .image {
  min-height: 350px;
  background-size: cover;
  /*min-width: 330px;*/
  background-position-y: center;
  overflow: hidden;
  cursor: pointer;
}

.destination .change_destination_heading h2 {
  padding-top: 220px;
  color: #fff;
  direction: rtl;
  font-size: 3em;
  position:relative;
}

.destination > .image.abu-dhabi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/abu-dhabi.jpg)}
.destination > .image.amsterdam {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/amsterdam.jpg)}
.destination > .image.antalya {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/antalya.jpg)}
.destination > .image.athens {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/athens.jpg)}
.destination > .image.ayia-napa {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/ayia-napa.jpg)}
.destination > .image.ayia-napa {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/ayia-napa.jpg)}
.destination > .image.baden-baden {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/baden-baden.jpg)}
.destination > .image.baku {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/baku.jpg)}
.destination > .image.barcelona {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/barcelona.jpg)}
.destination > .image.basel {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/basel.jpg)}
.destination > .image.batumi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/batumi.jpg)}
.destination > .image.belgrade {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/belgrade.jpg)}
.destination > .image.berlin {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/berlin.jpg)}
.destination > .image.bologna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/bologna.jpg)}
.destination > .image.bordeaux {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/bordeaux.jpg)}
.destination > .image.bratislava {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/bratislava.jpg)}
.destination > .image.brussels {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/brussels.jpg)}
.destination > .image.bucharest {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/bucharest.jpg)}
.destination > .image.budapest {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/budapest.jpg)}
.destination > .image.budva {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/budva.jpg)}
.destination > .image.burgas {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/burgas.jpg)}
.destination > .image.cluj-napoca {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/cluj-napoca.jpg)}
.destination > .image.craiova {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/craiova.jpg)}
.destination > .image.crete {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/crete.jpg)}
.destination > .image.debrecen {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/debrecen.jpg)}
.destination > .image.dubai {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/dubai.jpg)}
.destination > .image.dubrovnik {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/dubrovnik.jpg)}
.destination > .image.eilat {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/eilat.jpg)}
.destination > .image.geneva {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/geneva.jpg)}
.destination > .image.iasi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/iasi.jpg)}
.destination > .image.istanbul {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/istanbul.jpg)}
.destination > .image.katowice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/katowice.jpg)}
.destination > .image.kaunas {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/kaunas.jpg)}
.destination > .image.kiev {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/kiev.jpg)}
.destination > .image.kos {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/kos.jpg)}
.destination > .image.krakow {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/krakow.jpg)}
.destination > .image.larnaca {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/larnaca.jpg)}
.destination > .image.lithuania {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/lithuania.jpg)}
.destination > .image.london {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/london.jpg)}
.destination > .image.lyon {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/lyon.jpg)}
.destination > .image.madrid {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/madrid.jpg)}
.destination > .image.manchester {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/manchester.jpg)}
.destination > .image.marseille {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/marseille.jpg)}
.destination > .image.memmingen {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/memmingen.jpg)}
.destination > .image.milan {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/milan.jpg)}
.destination > .image.moscow {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/moscow.jpg)}
.destination > .image.nantes {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/nantes.jpg)}
.destination > .image.naples {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/naples.jpg)}
.destination > .image.nice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/nice.jpg)}
.destination > .image.nuremberg {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/nuremberg.jpg)}
.destination > .image.paphos {background-image:  url(https://cdn.cookiecrumbz.com/images/destinations/1680/paphos.jpg)}
.destination > .image.paris {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/paris.jpg)}
.destination > .image.poznan {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/poznan.jpg)}
.destination > .image.prague {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/prague.jpg)}
.destination > .image.rhodes {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/rhodes.jpg)}
.destination > .image.riga {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/riga.jpg)}
.destination > .image.rome {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/rome.jpg)}
.destination > .image.sibiu {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/sibiu.jpg)}
.destination > .image.sofia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/sofia.jpg)}
.destination > .image.tbilisi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/tbilisi.jpg)}
.destination > .image.thessaloniki {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/thessaloniki.jpg)}
.destination > .image.timisoara {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/timisoara.jpg)}
.destination > .image.tivat {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/tivat.jpg)}
.destination > .image.toulouse {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/toulouse.jpg)}
.destination > .image.varna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/varna.jpg)}
.destination > .image.venice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/venice.jpg)}
.destination > .image.vienna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/vienna.jpg)}
.destination > .image.vilnius {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/vilnius.jpg)}
.destination > .image.warsaw {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/warsaw.jpg)}
.destination > .image.zagreb {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/zagreb.jpg)}
.destination > .image.limassol {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/limassol.jpg)}
.destination > .image.izmir {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/izmir.jpg)}
.destination > .image.suceava {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/suceava.jpg)}
.destination > .image.turin {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/turin.jpg)}
.destination > .image.kusadasi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/kusadasi.jpg)}
.destination > .image.sharm-el-sheikh {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/sharm-el-sheikh.jpg)}
.destination > .image.catania {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/catania.jpg)}
.destination > .image.corfu {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/corfu.jpg)}
.destination > .image.st-pauls-bay {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/st-pauls-bay.jpg)}
.destination > .image.protaras {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/protaras.jpg)}
.destination > .image.nicosia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/nicosia.jpg)}
.destination > .image.albena {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/albena.jpg)}
.destination > .image.euro-disney {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/euro-disney.jpg)}
.destination > .image.athens-riviera {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/athens-riviera.jpg)}
.destination > .image.halkidiki {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/halkidiki.jpg)}
.destination > .image.kyrenia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/kyrenia.jpg)}
.destination > .image.bari {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/bari.jpg)}
.destination > .image.chisinau {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/1680/chisinau.jpg)}


.destination > .image {position:relative}
.destination > .image::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgb(0 0 0 /0%), rgb(0 0 0 /0%), rgb(0 0 0 /80%));
}

.destination > .heading_wrapper {
  margin: 2em auto 2em;
  display: flex;
  max-width: 1316px;
  justify-content: center;
}

.destination > .heading_wrapper > .heading {
  max-width: 1316px;
  display: flex;
  align-items: center;
}

.destination > .heading_wrapper >  .heading > h1 {
  font-weight:800;
  padding:0 0 0 .2em;
  color: #0795b7;
  font-size: 2.8em;
  text-align: right;
}

.destination > .heading_wrapper >  .heading > h1 > .city_name_wrapper {
    color: #01bddd;
    font-weight: 800;
    cursor: pointer;
}

.destination > .heading_wrapper >  .heading > h1 > .city_name_wrapper > .city_name {
  border-bottom: 1px dashed;
}


.destination > .heading_wrapper > .heading > .icon {
  color:#01bddd;
  font-size: 1.8em;
  margin-bottom:.2em;
}

.monthHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5em 1em;
  background: #f5f5f5;
  color: #333;
  text-align: right;
}

.monthHeader {
  border:1px solid #aaa;
  border-radius: 25px;
}


.card {
  background: inherit;
  border-radius: 0;
  filter: none;
  border:1px solid #ccc;
}

.month_card .photo {
 
  display:flex;
  justify-content:space-between; 
  color:#fff;
  opacity:0.9;
  min-height:195px;
  background-position:50% 50%;
  background-size:cover;
  background-repeat:no-repeat;
  -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: fadeIn 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 2s; /* Firefox < 16 */
        -ms-animation: fadeIn 2s; /* Internet Explorer */
         -o-animation: fadeIn 2s; /* Opera < 12.1 */
            animation: fadeIn 2s;

}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: .9; }
}


.monthHeader h6 {
  font-weight: 800;
  line-height: 1;
  margin: 0;
}

.monthHeader .right {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    min-height: 45px;
    justify-content: center;
    align-items: center;
    /*background: #fff;
    border: 1px solid #aaa;*/
    background: #fff;
    /*border-radius: 5px;*/
    font-size: 1em;
    padding: .5em 0;
    border: 1px solid #ddd;
    border-radius: 15px;
    
}

.right > .line1 > h6 {
  font-size: 1.1em;
  color: #124b71;
}

.monthHeader .right .line2 {
  color: #666;
  font-size: .9em;
  font-weight: 700;
}

.monthHeader .left {
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: space-between;
  align-items: center;
}

.monthHeader .open {
  flex: 0;
}

.monthHeader .open > .icon {
  background: #fff;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #124b71;
  border: 1px solid #124b71;
}

.monthHeader .left .line2 {
  display: flex;
    align-items: baseline;
    font-size: 0.7em;
    font-weight: 700;
    color: #666;
}

.monthHeader .left .line2 .price {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.2;
  margin-left: 0.05em;
  color: #124b71;
}

.more-results {
  padding: 0.5em 2em;
  background-color: rgb(31 89 129);
  width: 25%;
  margin: 2em auto 4em;
  border-radius: 25px;
}

.more-results button {
  font-size: 1.5em;
  font-weight: 800;
  border: none;
  background: inherit;
  color: #fff;
  }

  .more-results > div {
    display:flex;
    cursor:pointer;
    justify-content: center; 
    align-items: center;
  }

  .more-results .icon {
    color: #fff;
    margin: 0.2em .2em 0 0;
    font-size: 1.25em;
  }

.left.stars {
  font-weight: 800;
  align-items: baseline;
}

.card.month {
  margin:0 auto;
  border:none;
}

.card.month .title.sideTab, .order_page .card .title.sideTab {
  padding: 0;
  font-size: 1.1rem;
  font-weight: 400;
  /*border-left: 0.25rem solid #01bddd;
  border-right: 0.25rem solid #01bddd;*/
  background-color: #0ead9a;
  font-weight: 600;
  -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    /*background: #01bddd;*/
    color: #fff;
    cursor: pointer;
    padding: 0px 0.5em;
    border-left: none;
    border-right: none;
}

.duration .duration_comment {
  font-weight: 700;
}

.destinationDealDetails.active {
  background-color: #fff !important;
  /*border: 1px solid #ccc;*/
  border: none;
}

.card.month .destinationDealDetails .hotelPhoto {
  height: 115px;
  width: -webkit-fill-available;
  background-position: 50%;
  background-size: cover;
  float: left;
  margin-right: 5px; 
  border-radius: 5px;
  flex: none;
  max-width:150px;
}

.card.month  .destinationDealDetails .hotelPhoto {
  height: 85px;
  width: 85px;
  max-width: 115px;
}


.lines.active, .more-results.active {
  display: block !important;
  background: #fff;
  border-bottom: 1px solid #bbb;
  border-radius: 0;
}

.lines .line {
  border-top: 1px solid #bbb;
  padding: 0 0.75em 0 0;
}

 .month .line .price .amount {
  font-size: .9em;
  /*font-size: 1em;*/
  font-weight: 700;
  color:#124b71;
}

div#afnu_filters_v2_wrapper {
  border: none;
}

div#afnu_filters_v2_wrapper div.inputbox {
  color: #333;
}

div#afnu_filters_v2_wrapper div.inputbox div.content {
  background: #1f5981;
  border: none;
  color: #fff;
  width: max-content;
}

div#afnu_filters_v2_wrapper div.search {
  justify-content: right;
}

div#afnu_filters_v2_wrapper .not-visible {
  display:none;
}

div#afnu_filters_v2_wrapper div.recommended {
    display: flex;
    align-items: center;
    color: #cf576f;
    font-size: 13px;
    padding: 0.7em;
    line-height: 1;
    margin-right: 215px;
    font-weight: 800;
    position:relative;
    margin-bottom: -3.3em;
}

div#afnu_filters_v2_wrapper div.window{
    font-size: 0.8em;
    margin: 0;
    border-radius:5px;
    /*padding: 0 1.5em 0.5em;*/
    align-items: center;
    min-width: 335px;
    overflow: auto;
    max-height: 650px;
    /*max-width: 88%;*/
}

div#afnu_filters_v2_wrapper div.search {
  /*flex-wrap: wrap;*/
  display: flex;
  /*justify-content: center;*/
}

div#afnu_filters_v2_wrapper div.search.not-visible {
  display: flex;
}

div#afnu_filters_v2_wrapper div.tab {
  display: flex;
  /*justify-content: center;*/
  margin: 1em auto 0.5rem;
  flex-wrap: wrap;
  /*padding-top: 3em;
  padding-bottom: 3em;
  border-bottom: 2px dashed #777;*/
  background: #fff;
  padding: 2em;
  border-radius: 25px;
  max-width: 500px;
  overflow: auto;
  max-height: 540px;
}


div#afnu_filters_v2_wrapper div.tab::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
  margin-left: 10px;
}

div#afnu_filters_v2_wrapper div.tab::-webkit-scrollbar-track {
  margin: 2em 0;
  border-radius: 20px;
}

div#afnu_filters_v2_wrapper div.tab::-webkit-scrollbar-thumb {

  background: #124b71;
  border-left: 4px white solid;
  background-clip: padding-box;
  /*border: 3px solid #124b71;*/  /* creates padding around scroll thumb */
}


div#afnu_filters_v2_wrapper div.tab > div {
  padding: 1em 2em;
}


div#afnu_filters_v2_wrapper div.tab.search { 
  display:flex;
}

div#afnu_filters_v2_wrapper div.header {
  min-height: 20px;
  font-size: 1.2em;
  color: #555;
}

div#afnu_filters_v2_wrapper div.close{
  display: none;
}

div#afnu_filters_v2_wrapper  h1 {
    width: 100%;
    font-size: 1.8em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

div#afnu_filters_v2_wrapper  h2 {
  font-weight: 600;
  font-size: 1.2em;
  width: 100%;
  color: #555;
  margin-bottom: 1em;

}

div#afnu_filters_v2_wrapper div.wrapper {
  display: contents;
  white-space: nowrap;
}

div#afnu_filters_v2_wrapper div.inputbox{
  /*border-radius: 5px;
  width: auto;
  height:    60px;*/

  text-align: right;
  padding:   0 0 0 0.5em;
  color:     #eee;
  margin: 0.4em 0;
  /*background-color: #fff;*/
}

div#afnu_filters_v2_wrapper div.inputbox.button {
  margin: 2em 0 2em 0;
  width: 100%;
}

div#afnu_filters_v2_wrapper div.inputbox.button.selected .content {
  color: #fff;
  background-color: #124b71;
}

div#afnu_filters_v2_wrapper div.inputbox.button .content {
  min-width: 48%;
  /*padding: .85em .5em;*/
}

div#afnu_filters_v2_wrapper div.inputbox.button .text {
  font-size: 0.85em;
  text-align: center !important;
}

div#afnu_filters_v2_wrapper div.inputbox  div.content {

  font-weight: 800;
  font-size: 1.3em;
  align-items: center;
  display: flex;
  /*line-height: 1.2;*/
  border: 1px solid #999;
  border-radius: 5px;
  padding: 0.5em 0.5em 0.5em 1em;
  cursor: pointer;
  color: #fff;
  background-color: #124b71;
}

div#afnu_filters_v2_wrapper div.inputbox  div.content div.icon {
  color:#aaa;
  display:inline-block;
  margin:0 0 0 .2em;
  font-size:0.9em;
}

div#afnu_filters_v2_wrapper div.inputbox div.content div.icon div.body {
  font-size: 0.9em;
  color:#ccc;
}

div#afnu_filters_v2_wrapper li {
  cursor: pointer;
}

div#afnu_filters_v2_wrapper .tab.when .button .content:hover {
  cursor: pointer;
  background: #124b71;
  color: #fff
}


div#afnu_filters_v2_wrapper li:hover {
  color: #cf576f;
  cursor: pointer;
}

div#afnu_filters_v2_wrapper div.inputbox   div.content div.text {
  width: 100%;
  text-align: right;
}

div#afnu_filters_v2_wrapper .search h1 {
  display: none;
}

div#afnu_filters_v2_wrapper .search h2 {
  display: none;
}

div#afnu_filters_v2_wrapper .search h6 {
  display: none;
}

div#afnu_filters_v2_wrapper .switch.mobile {
  display: none;
}

div#afnu_filters_v2_wrapper .switch.desktop {
  font-size: 1.2em;
  font-weight:700;
  color:#ddd;
  align-items: center;
  display: flex;
  width: 92%;
}

div#afnu_filters_v2_wrapper .btn.ok {
  padding:0.5em 0 0;
  width: 100%;
  margin: 0 auto;
}

.btn.ok > button {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
  border-radius: 5px;
  background:#124b71;
  width: 50%;
  padding: 0.5em;
}

div#afnu_filters_v2_wrapper .btn.search {
  background: #fed102;
  min-width: 15%;
  color: #000;
  font-size: 1.2rem;
  /* border: 1px solid #aaa; */
  box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
  border-radius: 5px;
  padding: 0.6em;
  width: 100%;
  border-radius: 10px;
  font-weight: 800;
}


newFilters.popup.filters_wrapper  {
  margin: 0 auto;
  position:fixed;
  bottom:0;
  z-index: 1;
  width: 100%;
}

/*
newFilters.filters {
  background: #efefef;
  padding: 2.2rem 1rem 2.5rem;
  border: 1px solid #999;
  margin: 20px auto 0;
  border-top: 6px solid #ccc;
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}*/

.newFilters.search {
/*    background: #fff;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;*/
    padding: 0.25em 0.5em;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: center;
    margin: 1em 0 0 0;
}


.newFilters.search::-webkit-scrollbar {
    display:none
}

.newFilters.search button{
    border-radius: 40px;
    padding: 0.8em 1.5em;
    min-width:115px;
    margin:0.5em 0 0.5em 0.5em;
    background-color:#124b71;
    color:#fff;
    border:0;
    font-weight: 800;
    flex: 1;
    cursor:pointer;
}

.newFilters.search .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    white-space: nowrap;
    padding: 0.2em 0.9em 0.2em;
    /*margin:0.5em 0 0.5em 0.5em;*/
    margin: 0.5em;
    background-color:#124b71;
    color:#fff;
    font-weight: 600;
    flex-direction: row-reverse;
    cursor: pointer;
}

.newFilters.search .button > label {
    padding-left:0.3em;
    font-size: 0.9em;
}

.filtersHeader {
  font-size: 1.1em;
}

.newFilters.search .button > .spacing {
    /*display:none;*/
}

.newFilters.search .button > .icon {
    padding-bottom: 0.3em;
    /*display: none;*/
}

.newFilters.search .button.active {
    background-color: #01bddd;
}


.newFilters.search .button.active > .icon {
    display:none;
}



.newFilters .filters_wrapper li {
    text-decoration: none;
    font-weight: 600;
    color: #555;
    cursor: pointer;
}

.newFilters .filters_wrapper li:hover {
  font-weight: 600;
  color: #0f8daf;
}

.newFilters .filters.visible {
    /*position:absolute;
    width:100%;
    margin-top:60px;*/
    z-index: 100;
    /*border-bottom:1px solid #aaa;*/
    transition: all 1s ease;
    transform: translate(0, 0%);
    bottom: 0;
    top: 1em;
    width: 100%;
    z-index: 1000;
}

.newFilters .filters.visible .content {
    max-width: 960px;;
    margin: 0 auto;
    max-height: 200px;
    /*overflow: scroll;*/
}

.newFilters .filters .selected {
    font-weight: 800;
    color: #124b71;
}

.newFilters .filters .filtersHeader {
    max-width: 960px;;
    margin: 0 auto;
}

.destinationDealDetails.active {
    max-width: 95%;
    background-color: #fff;
}

/* Card related */
.deck {
    margin-top: 0.5em !important;
    padding-bottom:2em;
}

.hidden {
  display:none !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.container {
    cursor: grab;
    overflow: auto;
}

.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.cardScroll {
      display:flex;
      align-items: center;
}

.cardScroll .scroll {
    display:flex;
    overflow-y:hidden;
    /*scroll-behavior: : smooth;*/
}

/* Hide scrollbar itself */
.scroll::-webkit-scrollbar {
  width: 10px;
}

.deck {
  padding: 0;
  background: inherit;
}

.btn {
    padding: 0;
    color: #0f8daf;
    display:inline-block;
    font-size:3rem;
    z-index:10;
    /*opacity: 0.7 */
}

.btn.right {
  display:none;
  margin:-0.75em 0em;
  padding:.2em 0;
  opacity: 0.7 ;
}

.btn.left {
  padding:.2em 0;
  opacity: 0.7 ;
}

.newFilters.search::-webkit-scrollbar {
  width: 0;
}

.newFilters.search {
  padding: 0 0.5em;
}

.card .subtitle_item {
  display:flex;
  font-size: 0.75em;
}

.card .subtitle_item .icons_wrapper {
  max-height: 40px;
  opacity: 0.92;
  background: rgb(29, 178, 160);
  /*border-radius: 10px;
  margin: 0px 1.5em;
  padding: 1em 4.5em;*/
  font-weight: 600;
}


.card.month .subtitle_item .icons_wrapper {
  display:none;
  margin: 1em auto 0px
}

.card .subtitle_item .icons_content {
  color: rgb(255, 255, 255);
  display: flex;
}

.card.month .headerTitle {
    position: absolute;
    color: rgb(255, 255, 255);
    z-index: 1;
    width: 100%;
}

.card.month .header  .title  {
  opacity:0.6;
  background-color:#000;
  height: 4em;
}

.card .flightIcon {
  font-size: 1.2em;
  line-height: 1.3;
}

.card.month .flightIcon {
  font-size: 1.3em;
}

.card.month .plusIcon {
  font-size: 2.5em;
  font-weight: 700;
  line-height: 1;
  padding: 0px 0.2em;
}

.card .hotelIcon {
  font-size: 1.2em;
  line-height: 1.3;
  position: initial;
}

.card.month .hotelIcon {
  font-size: 1.3em;
}

.card.month h6 {
  font-weight: 700;
    line-height: 1.5;
    /*width: min-content;*/
}

.card.month .subtitle_item {
  margin: 0.2em auto 0;
}

.card.month .subtitle_item div {
    background: transparent;
}

nav.mobile_navigation  {
  display:none;
}

h1.hidden {display:none}

.deck-stars.hidden {display:none}

@media only screen and (max-width: 500px) {


  .card.month {
    margin: 0 1em; 
  }

  .card.month .card{
    margin:1em auto;
  }
  .destination .icons_wrapper {
    font-size: 1.1em !important;
  }

  .card.month .title.sideTab, .order_page .card .title.sideTab {
    padding: 0px 0.25em;
  }
  


  .destination > div.image {
    direction: ltr;
  }

  /*.destination > div.image > div.flightAndHotelIcon {
    font-size: 0.85em;
    display: flex;
    justify-content: left;
    height: 52vw;
    align-items: end;
    width: 98%;
    margin: 0 0 0 .5em;
    direction: rtl;
}*/

.monthHeader .right {
  font-size: .9em;
  border-radius: 15px;
}

  .content {
    font-size: 1em;
  }

  div#afnu_filters_v2_wrapper div.window{
    padding: 0 1.5em 0.5em;
}

.destination > .heading_wrapper {
  margin: 1em 0 0;
}
  
    .destination {
      /*margin: 55px 1em 0;*/
    }

    .destination .title {
      display: block;
      background: #01bddd;
      color: #fff;
      padding: .3em;
      max-height: 48px;
      /*margin-bottom: .5em;*/
    }

    .destination .title .subset {
      font-size: 0.8em;
    }  

    .destination > .image {
      min-height: 57vw;
      border-radius: 25px;
      margin: 1em;
      background-size: cover !important;
    }
    
    .destination .change_destination_heading h2 {
      padding-top: 175px;
      font-size: 1.6em;
    }

    
    .destination > .image.abu-dhabi {background-image:  url(https://cdn.cookiecrumbz.com/images/destinations/abu-dhabi.jpg)}
    .destination > .image.amsterdam {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/amsterdam.jpg)}
    .destination > .image.antalya {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/antalya.jpg)}
    .destination > .image.athens {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/athens.jpg)}
    .destination > .image.ayia-napa {background-image:  url(https://cdn.cookiecrumbz.com/images/destinations/ayia-napa.jpg)}
    .destination > .image.baden-baden {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/baden-baden.jpg)}
    .destination > .image.baku {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/baku.jpg)}
    .destination > .image.barcelona {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/barcelona.jpg)}
    .destination > .image.basel {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/basel.jpg)}
    .destination > .image.batumi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/batumi.jpg)}
    .destination > .image.belgrade {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/belgrade.jpg)}
    .destination > .image.berlin {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/berlin.jpg)}
    .destination > .image.bologna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bologna.jpg)}
    .destination > .image.bordeaux {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bordeaux.jpg)}
    .destination > .image.bratislava {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bratislava.jpg)}
    .destination > .image.brussels {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/brussels.jpg)}
    .destination > .image.bucharest {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bucharest.jpg)}
    .destination > .image.budapest {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/budapest.jpg)}
    .destination > .image.budva {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/budva.jpg)}
    .destination > .image.burgas {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/burgas.jpg)}
    .destination > .image.cluj-napoca {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/cluj-napoca.jpg)}
    .destination > .image.craiova {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/craiova.jpg)}
    .destination > .image.crete {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/crete.jpg)}
    .destination > .image.debrecen {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/debrecen.jpg)}
    .destination > .image.dubai {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/dubai.jpg)}
    .destination > .image.dubrovnik {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/dubrovnik.jpg)}
    .destination > .image.eilat {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/eilat.jpg)}
    .destination > .image.geneva {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/geneva.jpg)}
    .destination > .image.iasi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/iasi.jpg)}
    .destination > .image.istanbul { background-image:  url(https://cdn.cookiecrumbz.com/images/destinations/istanbul.jpg)}
    .destination > .image.katowice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/katowice.jpg)}
    .destination > .image.kaunas {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kaunas.jpg)}
    .destination > .image.kiev {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kiev.jpg)}
    .destination > .image.kos {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kos.jpg)}
    .destination > .image.krakow {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/krakow.jpg)}
    .destination > .image.larnaca {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/larnaca.jpg)}
    .destination > .image.lithuania {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/lithuania.jpg)}
    .destination > .image.london {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/london.jpg)}
    .destination > .image.lyon {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/lyon.jpg)}
    .destination > .image.madrid {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/madrid.jpg)}
    .destination > .image.manchester {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/manchester.jpg)}
    .destination > .image.marseille {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/marseille.jpg)}
    .destination > .image.memmingen {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/memmingen.jpg)}
    .destination > .image.milan {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/milan.jpg)}
    .destination > .image.moscow {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/moscow.jpg)}
    .destination > .image.nantes {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/nantes.jpg)}
    .destination > .image.naples {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/naples.jpg)}
    .destination > .image.nice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/nice.jpg)}
    .destination > .image.nuremberg {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/nuremberg.jpg)}
    .destination > .image.paphos {background-image:  url(https://cdn.cookiecrumbz.com/images/destinations/paphos.jpg)}
    .destination > .image.paris {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/paris.jpg)}
    .destination > .image.poznan {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/poznan.jpg)}
    .destination > .image.prague {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/prague.jpg)}
    .destination > .image.rhodes {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/rhodes.jpg)}
    .destination > .image.riga {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/riga.jpg)}
    .destination > .image.rome {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/rome.jpg)}
    .destination > .image.sibiu {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/sibiu.jpg)}
    .destination > .image.sofia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/sofia.jpg)}
    .destination > .image.tbilisi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/tbilisi.jpg)}
    .destination > .image.thessaloniki {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/thessaloniki.jpg)}
    .destination > .image.timisoara {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/timisoara.jpg)}
    .destination > .image.tivat {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/tivat.jpg)}
    .destination > .image.toulouse {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/toulouse.jpg)}
    .destination > .image.varna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/varna.jpg)}
    .destination > .image.venice {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/venice.jpg)}
    .destination > .image.vienna {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/vienna.jpg)}
    .destination > .image.vilnius {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/vilnius.jpg)}
    .destination > .image.warsaw {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/warsaw.jpg)}
    .destination > .image.zagreb {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/zagreb.jpg)}
    .destination > .image.limassol {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/limassol.jpg)}
    .destination > .image.izmir {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/izmir.jpg)}
    .destination > .image.suceava {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/suceava.jpg)}
    .destination > .image.turin {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/turin.jpg)}
    .destination > .image.kusadasi {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kusadasi.jpg)}
    .destination > .image.sharm-el-sheikh {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/sharm-el-sheikh.jpg)}
    .destination > .image.catania {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/catania.jpg)}
    .destination > .image.corfu {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/corfu.jpg)}
    .destination > .image.st-pauls-bay {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/st-pauls-bay.jpg)}
    .destination > .image.euro-disney {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/euro-disney.jpg)}
    .destination > .image.athens-riviera {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/athens-riviera.jpg)}
    .destination > .image.halkidiki {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/halkidiki.jpg)}
    .destination > .image.kyrenia {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/kyrenia.jpg)}
    .destination > .image.bari {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/bari.jpg)}
    .destination > .image.chisinau {background-image: url(https://cdn.cookiecrumbz.com/images/destinations/chisinau.jpg)}

    
    .destination > .title > h2 {
      margin: 0;
    }

    .destination > .heading_wrapper >  .heading > h1 {
      color: #124b71;
      font-size: 1.7em;
      font-weight: 700;
    }

    .destination > .heading_wrapper >  .heading > h1 > .city_name_wrapper { 
      font-weight: 700;
    }

    .destination > .heading_wrapper > .heading > .icon {
      font-size: 1.3em;
    }

 
 
  .more-results {
    width: 75%;
    margin: 1em auto 4em;
  }

  .more-results button {
    font-size: 1.3em;
    font-weight: 700;
  }

  .more-results .icon {
    font-size: 1em;
  }
 
    /*filters related */

    .newFilters.search .button {
        margin: 0.5em 0.5em 0.5em 0;
    }

    .newFilters.search {
        justify-content: space-between;
    }

    main {
        /*padding-top: 0 !important*/
    }

    .newFilters .filters{
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-radius: 10px 10px 0 0;
        padding-bottom: 0;
        padding-top: 1.5em;
    }

    .newFilters .filters.visible {
        position:initial;
        width:100%;
        position:fixed;
        top: inherit;
    }

    .newFilters .filters.not-visible {
        display: none !important;
    }
    
    .newFilters .filters .not-visible {
        display: none !important;
    }

    /* card related */
    .cardScroll .scroll {
        /*padding-right: 2.5em;*/
        
        scroll-behavior : smooth;
      }
    
      .newFilters.search {
        display: flex;
      }
    
      header {
        margin-top: 0 !important;
      }
      .headerNav {
        margin: 0 auto;
        padding: 1em;
        z-index: 1000;
      }
    
      .btn.left {
        font-size: 1.8em;
        position:absolute;
        left: 0;
        background:#fff;
    
      }
    
      .btn.right {
        font-size: 1.8em;
        position:absolute;
        background:#fff;
      }
    
      .newFilters.popup.filters_wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 100;
    }
}